.dashboard-section {
    padding: 25px 17px 0px 17px;
}

.dashboard-section h5 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    color: #202224;
    margin-bottom: 20px;
}

.dashboard-box-1 {
    border: 1px solid #092C4C26;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px #092C4C40;
    background: #FFFFFF;
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.dashboard-box-1 img {
    margin-bottom: 10px;
    background-color: #EDF6FF;
    padding: 5px;
}

.dashboard-box-1 p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #092C4C;
    margin-bottom: 10px;
}

.dashboard-box-1 h6 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: #092C4C;
}

.dashboard-box-2 {
    border: 1px solid #D2AB6726;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px #D2AB6740;
    background: #FFFFFF;
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.dashboard-box-2 img {
    margin-bottom: 10px;
    background-color: #FFF8EC;
    padding: 5px;
}

.dashboard-box-2 p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #D2AB67;
    margin-bottom: 10px;
}

.dashboard-box-2 h6 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: #D2AB67;
}

.dashboard-box-3 {
    border: 1px solid #F2994A26;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px #F2994A40;
    background: #FFFFFF;
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.dashboard-box-3 img {
    margin-bottom: 10px;
    background-color: #FFF7F0;
    padding: 5px;
}

.dashboard-box-3 p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #F2994A;
    margin-bottom: 10px;
}

.dashboard-box-3 h6 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: #F2994A;
}

.dashboard-box-4 {
    border: 1px solid #00A65126;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px #00A65140;
    background: #FFFFFF;
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.dashboard-box-4 img {
    margin-bottom: 10px;
    background-color: #F0FFF7;
    padding: 5px;
}

.dashboard-box-4 p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #00A651;
    margin-bottom: 10px;
}

.dashboard-box-4 h6 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: #00A651;
}

.dashboard-box-5 {
    border: 1px solid #EB575726;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px #EB575740;
    background: #FFFFFF;
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.dashboard-box-5 img {
    margin-bottom: 10px;
    background-color: #FFF1F1;
    padding: 5px;
}

.dashboard-box-5 p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #EB5757;
    margin-bottom: 10px;
}

.dashboard-box-5 h6 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: #EB5757;
}

@media (min-width:992px) and (max-width:1140px) {
    .dashboard-box-1 p, 
    .dashboard-box-2 p, 
    .dashboard-box-3 p, 
    .dashboard-box-4 p, 
    .dashboard-box-5 p{
        min-height: 30px  !important;
        font-size: 13px;
    }
}
@media (min-width:1200px) and (max-width:1299px) {
 
    .dashboard-box-1 p,
    .dashboard-box-2 p,
    .dashboard-box-3 p,
    .dashboard-box-4 p,
    .dashboard-box-5 p {
        font-size: 13px;
        min-height: 30px  !important;
    }

}