.topbar {
    background-color: #fff;
}

.topbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
}

.topbar-content-text h6 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    color: #202224;
    margin-bottom: 6px;
}

.topbar-content-text p {
    font-family: "Inter", sans-serif;
    color: #202224;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
}

.topbar-notifications .MuiBadge-badge {
    font-size: 10px !important;
    background-color: #00A651 !important;
    color: #fff !important;
    padding: 2px !important;

}

.topbar-notifications {
    display: flex;
    align-items: center;
}

.notification .MuiMenu-paper {
    width: 400px !important;
    border: 0.8px solid #D2AB67 !important;
    /* left: 1114px !important; */
    box-shadow: 0px 4px 4px 0px #D2AB6757 !important;
    background: #FFFFFF !important;
    border-radius: 12px !important;
}

.notification .MuiMenu-paper .MuiMenu-list {
    padding: 0px !important;
}

.notification .notification-menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 18px 16px !important;
}

.notification .notification-menu {
    border-bottom: 1px solid #D2AB67;
}

.notification .notification-menu h5 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    color: #383838;
}

.notification .notification-menu Button {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.36px;
    color: #383838;
    text-transform: unset;
}

.notification .notification-menu Button:hover {
    background-color: unset;
}

.notification .notification-menu p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #092C4C;
}

.notification .notification-menu-2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 18px 16px !important;
}

.notification .notification-menu-2 h6 {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.36px;
    color: #000;
}

.notification .notification-menu-2 span {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: #383838CC;
}

.notification .notification-menu-2 p {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: #383838CC;
}

.notification .notification-menu-3 p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #000;
    position: relative;
}

.notification .notification-menu-3 {
    padding: 0px 20px 15px 20px !important;
}

.notification .notification-menu-3 p::before {
    content: "";
    position: absolute;
    top: 10px;
    left: -11px;
    width: 4px;
    height: 4px;
    background-color: #000;
    border-radius: 60px;
}

/* profile */
.profiles {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.profiles h4 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    color: #202224;
}

.profile .MuiMenu-paper {
    /* width: 170px !important; */
    /* border: 0.8px solid #D2AB67 !important; */
    /* left: 1368px !important; */
    box-shadow: 0px 4px 4px 0px #D2AB6757 !important;
    background: #FFFFFF !important;
    border-radius: 5px !important;
}

.profile .MuiMenu-paper .MuiMenu-list {
    padding: 8px 0px !important;
}
.profile .MuiMenu-paper .MuiMenu-list .MuiMenuItem-root{
  /* padding: 5px 8px !important; */
  font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
}
.profile .MuiMenu-paper .MuiMenu-list .MuiMenuItem-root:hover {
    background: #D2AB67 !important;
    color: #fff !important;
}

.profile .profile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 16px !important;
}

.profile .profile-menu {
    border-bottom: 1px solid #D2AB67;
}

.profile .profile-menu h5 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    color: #383838;
}

.profile .profile-menu .MuiSvgIcon-root {
    color: #ff0000;
    font-size: 20px;
    cursor: pointer;
}

.profile .profile-menu-2 {
    padding: 5px 16px 15px 16px !important;
    position: relative;
}

/* new added styles */
.profile .profile-menu-2 Button {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22.4px;
    color: #4F4F4F;
    margin-bottom: 10px;
    text-transform: unset;
}

.profile .profile-menu-2 .edit_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.profile .profile-menu-2 Button img {
    margin-left: 5px;
    width: 12px;
}

.profile .profile-menu-2 h6 img {
    margin-left: 4px;
}

.profile .profile-menu-2 .MuiOutlinedInput-input {
    color: #4F4F4F;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    padding: 14px !important;
}

.profile .profile-menu-2 .MuiOutlinedInput-notchedOutline {
    border: 1px solid #D2AB6799;
    box-shadow: 0px 2px 12px 0px #D2AB6757;
    border-radius: 8px;
    width: 100% !important;
}

.profile .profile-menu-2 .MuiInputLabel-root.Mui-focused {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: #4F4F4F;
}

.profile .profile-menu-2 .MuiInputLabel-root {
    top: -3px !important;
}

.profile .profile-menu-3 {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    padding: 0px !important;
}

.profile .profile-menu-3 .select-file {
    border: 1px dashed #E5E5E5;
    padding: 15px 16px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    width: 231px;
}

.profile .profile-menu-3 .select-file img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-bottom: 10px;
}

.profile .profile-menu-3 .select-file h6 {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #0EA5E9;
    margin-bottom: 5px !important;
}

.profile .profile-menu-3 .select-file h6 span {
    color: #525252;
}

.profile .profile-menu-3 .select-file p {
    font-family: "Inter", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: #A3A3A3;
}

.profile .profile-menu-3 .file-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile .profile-menu-3 .file-btn .MuiButton-root:first-child {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #EB5757;
    text-transform: capitalize;
}

.profile .profile-menu-3 .file-btn .MuiButton-root:first-child:hover {
    background-color: unset !important;
}

.profile .profile-menu-3 .file-btn .MuiButton-root:last-child {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    background-color: hsl(38, 54%, 61%);
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px #38383826;
    padding: 8px 15px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin-left: 20px;
}