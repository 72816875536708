.dashboard-section {
    padding: 25px 17px 0px 17px;
}

.dashboard-section h5 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    color: #202224;
    margin-bottom: 20px;
}


.charts-section {
    margin-top: 50px;
    position: relative;
}

.piechart {
    background-color: #FFFFFF;
    border: 0.8px solid #C9C9C926;
    box-shadow: 0px 2px 4px 0px #00000040;
    border-radius: 6px;
    padding: 18px 20px;
    height: 303px;
}

.piechart h5 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    color: #202224;
    margin-bottom: 15px;
}

.wavechart {
    border: 0.8px solid #C9C9C926;
    box-shadow: 0px 2px 4px 0px #00000040;
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 18px 20px;
    height: 303px;
}

.wavechart h5 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    color: #202224;
    margin-bottom: 15px;

}

.wavechart .apexcharts-yaxis-label {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
    color: #383838 !important;
    font-size: 12px !important;
    line-height: 16.94px !important;
}

.wavechart .apexcharts-xaxis-label {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
    color: #383838 !important;
    font-size: 12px !important;
    line-height: 16.94px !important;
}

.wavechart .apexcharts-legend-text {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
    color: #383838 !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
}

.wavechart .apexcharts-yaxis-title-text {
    font-family: 'Inter', sans-serif !important;
    color: #383838 !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
}

.wavechart .apexcharts-toolbar {
    top: -30px !important;
}

.charts-section .chart-year {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 288px;
    right: 10px;
}

.charts-section .chart-year .MuiOutlinedInput-input {
    width: 33px !important;
    padding: 5px 8px !important;
}

.charts-section .chart-year .MuiOutlinedInput-root {
    height: 34px !important;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: #383838 !important;
}

.charts-section .chart-year .MuiOutlinedInput-root .MuiIconButton-root {
    padding: 5px !important;
}

.charts-section .chart-year .MuiOutlinedInput-root .MuiSvgIcon-root {
    font-size: 18px !important;
}

.charts-section .chart-year .MuiInputLabel-root {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    color: #383838 !important;
    top: 3px !important;
}
.employee-checkin-date{
    display: flex;
    justify-content: space-between !important;
 }

 .employee-checkin-date .MuiFormControl-root:first-child{
    margin-right: 20px;
 }
 .employee-checkin-date .MuiFormControl-root{
    width: 100% !important;
 }
 .employee-checkin-date .MuiOutlinedInput-input {
    /* width: 150px !important; */
    padding: 5px 8px !important;
}
.employee-checkin-date  .MuiOutlinedInput-root {
    height: 40px !important;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #383838 !important;
}
.employee-checkin-date .MuiInputLabel-root {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    color: #383838 !important;
    top: 3px !important;
}
.employee-checkin-date .MuiButton-root{
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #FFFFFF;
    text-transform: capitalize;
    background: linear-gradient(0deg, #D2AB67, #D2AB67), linear-gradient(180deg, rgba(255, 255, 255, 0.04) -44.73%, rgba(255, 255, 255, 0) 126.04%);
    border: 1px solid #D2AB67;
    padding: 10px 18px 10px 18px !important;
}
/* employess page */
.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px
}
.loading .MuiCircularProgress-svg{
    color: #D2AB67 !important;
}
.loading .MuiCircularProgress-root{
width: 50px !important;
height: 50px !important;
}
.employees {
    margin: 20px 15px 0px 20px;
}

.employees h5 {
    font-family: 'Inter', sans-serif !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    color: #202224;
}

.employees-main {
    margin-top: 20px;
}

.employees-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFEED4;
    padding: 12px;
    border-radius: 12px 12px 0px 0px !important;
    overflow: hidden;
}
.tickect-content{
    display: flex !important;
    justify-content: flex-end !important;
}
.search-options {
    display: flex;
    align-items: center;
}

.search-options .MuiFormControl-root .MuiInputBase-formControl {
    background-color: #FFFFFF;
    padding: 9px !important;
    border-radius: 6px !important;
}

.search-options .MuiFormControl-root .MuiInputBase-formControl .MuiInputAdornment-root .MuiSvgIcon-root {
    font-size: 18px !important;
    color: #383838 !important;
}

.search-options .MuiOutlinedInput-input {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    padding: 0px 0px 0px 10px !important;
    color: #383838;
}

.search-options .MuiOutlinedInput-notchedOutline {
    border: 0.6px solid #D2AB6780 !important;
    border-radius: 6px !important;
}

.pagination-options {
    display: flex;
    align-items: center;
    position: relative;
}

.pagination-options::before {
    position: absolute;
    left: -120px;
    top: -44px;
    content: "";
    background-color: #F3D6A5;
    width: 40px;
    height: 128px;
    animation: moveSideToSide 3s infinite alternate ease-in-out;
    margin-left: 20px;
}

.pagination-options::after {
    position: absolute;
    left: -140px;
    top: -44px;
    content: "";
    background-color: #F3D6A5;
    width: 25px;
    height: 128px;
    animation: moveSideToSide 3s infinite alternate ease-in-out;
}

@keyframes moveSideToSide {
    0% {
        transform: translateX(0) rotate3d(-6, 4, 2, 76deg);
    }

    100% {
        transform: translateX(-200px) rotate3d(-6, 4, 2, 76deg);

    }
}

.pagination-options .MuiTablePagination-actions {
    margin-left: 10px !important;
}

.pagination-options .MuiTablePagination-toolbar {
    padding: 0px !important;
    min-height: 0px !important;
}

.pagination-options .MuiTablePagination-displayedRows {
    margin: 0px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #383838;
    letter-spacing: 2px;
}

.pagination-options .MuiTablePagination-displayedRows span {
    color: #38383880;
    margin: 0px 1px;

}

.pagination-options .MuiTablePagination-actions .MuiIconButton-root {
    padding: 4px 4px !important;
}

.pagination-options .MuiTablePagination-actions .MuiIconButton-root:hover {
    background-color: unset !important;
}

.filter {
    margin-left: 10px;
}

.filter .MuiIconButton-root .MuiSvgIcon-root {
    color: #383838;
}

.filter-options .MuiMenu-paper {
    width: 500px;
    max-width: 500px;
    border: 0.8px solid #D2AB67 !important;
    box-shadow: 0px 4px 4px 0px #D2AB6757 !important;
    background: #FFFFFF !important;
    border-radius: 12px !important;
    right: 0px !important;

}

.filter-options .MuiMenu-list {
    padding: 0px !important;
}

.filter-options .filter-menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 16px !important;
}

.filter-options .filter-menu {
    border-bottom: 1px solid #D2AB67;
}

.filter-options .filter-menu h6 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    color: #383838;
}

.filter-options .filter-menu .MuiSvgIcon-root {
    color: #ff0000;
    cursor: pointer;
    font-size: 20px;
}

.filter-options .filter-status {
    padding: 30px 16px 25px 16px;
    border-bottom: 1px solid #D2AB67;
}

.filter-options .filter-status .MuiFormLabel-root {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: #383838;
}

.filter-options .filter-status .MuiFormGroup-root {
    display: flex !important;
    flex-direction: row !important;
    margin-top: 10px;
    margin-left: 20px;
}

.filter-options .filter-status .MuiFormGroup-root .active {
    color: #00A651 !important;
}

.filter-options .filter-status .MuiFormGroup-root .active .MuiRadio-colorPrimary {
    color: #00A651 !important;
}

.filter-options .filter-status .MuiFormGroup-root .active .MuiFormControlLabel-label {
    color: #00A651 !important;
}

.filter-options .filter-status .MuiFormGroup-root .inactive {
    color: #EB5757 !important;
}

.filter-options .filter-status .MuiFormGroup-root .inactive .MuiRadio-colorPrimary {
    color: #EB5757 !important;
}

.filter-options .filter-status .MuiFormGroup-root .inactive .MuiFormControlLabel-label {
    color: #EB5757 !important;
}

.filter-options .filter-status .MuiFormGroup-root .InProgress {
    color: #2F80ED !important;
}

.filter-options .filter-status .MuiFormGroup-root .InProgress .MuiRadio-colorPrimary {
    color: #2F80ED !important;
}

.filter-options .filter-status .MuiFormGroup-root .InProgress .MuiFormControlLabel-label {
    color: #2F80ED !important;
    background-color: #EDF5FF;
    padding: 2px 8px;
    border-radius: 4px;
}

.filter-options .filter-status .MuiFormGroup-root .Pending {
    color: #F2994A !important;
}

.filter-options .filter-status .MuiFormGroup-root .Pending .MuiRadio-colorPrimary {
    color: #F2994A !important;
}

.filter-options .filter-status .MuiFormGroup-root .Pending .MuiFormControlLabel-label {
    color: #F2994A !important;
    background-color: #FFF7F0;
    padding: 2px 8px;
    border-radius: 4px;
}

.filter-options .filter-status .MuiFormGroup-root .active1 {
    color: #00A651 !important;
}

.filter-options .filter-status .MuiFormGroup-root .active1 .MuiRadio-colorPrimary {
    color: #00A651 !important;
}

.filter-options .filter-status .MuiFormGroup-root .active1 .MuiFormControlLabel-label {
    color: #00A651 !important;
    background-color: #E5FCF0;
    padding: 2px 8px;
    border-radius: 4px;
}

.filter-options .filter-status .MuiFormGroup-root .inactive1 {
    color: #EB5757 !important;
}

.filter-options .filter-status .MuiFormGroup-root .inactive1 .MuiRadio-colorPrimary {
    color: #EB5757 !important;
}

.filter-options .filter-status .MuiFormGroup-root .inactive1 .MuiFormControlLabel-label {
    color: #EB5757 !important;
    background-color: #FFF1F1;
    padding: 2px 8px;
    border-radius: 4px;
}

.filter-options .filter-status .MuiFormControlLabel-label {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #383838;
}

.filter-options .filter-status .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover {
    background-color: unset !important;
}

.filter-options .filter_dropdown {
    margin-top: 20px;
}

.filter-options .filter-tickets {
    margin-top: 0px !important;
}

.filter-options .filter_dropdown .MuiOutlinedInput-input {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: "Inter", sans-serif;
}

.filter-options .filter_dropdown .MuiInputLabel-formControl .MuiInputLabel-formControl {
    font-family: "Inter", sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22.4px !important;
    color: #4F4F4F !important;

}
.filter-options .filter-tickets-1{
    margin-top: 20px !important;
}
.filter-options .filter_dropdown .MuiInputLabel-formControl {
    background-color: #FFF !important;
    padding: 0px !important;
}

.filter-options .filter_dropdown .MuiFormControl-root {
    font-family: "Inter", sans-serif !important;
    width: 100%;
    box-shadow: 0px 2px 12px 0px #D2AB6757;
    border-radius: 4px;

}
.filter-status .staff-filter{
    margin: 0px !important;
}
.filter-options .filter_date_picker {
    margin-top: 30px !important;
}

.filter-options .apply_fliters {
    padding: 14px 22px 14px 22px;
    display: flex;
    justify-content: center;
}
.filter-btn{
    border:  none !important;
    padding: 18px 22px 18px 22px !important;
    margin-top: 0px !important;
}
.filter-options .apply_fliters .MuiButtonBase-root {
    background: #D2AB67 !important;
    color: #fff !important;
    border-radius: 5px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    border: unset !important;
    text-transform: unset !important;
    font-family: "Inter", sans-serif;
    width: 100%;
    max-width: 80%;
}
.filter-options .apply_fliters .reset-filter{
    margin-right: 15px;
}
.filter-options .filter-active {
    margin-top: 30px;
}
.tickect-filter{
    display: flex;
    align-items: center;
    margin: 40px 0px 20px 0px;
}
.tickect-filter .search-btn{
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    color: #FFFFFF;
    text-transform: capitalize;
    background: linear-gradient(0deg, #D2AB67, #D2AB67), linear-gradient(180deg, rgba(255, 255, 255, 0.04) -44.73%, rgba(255, 255, 255, 0) 126.04%);
    border: 1px solid #D2AB67;
    border-radius: 6px;
    padding: 13px 15px;
    margin-right: 10px;
}
.tickect-filter .MuiInputLabel-root{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #383838 !important;
}
.tickect-filter .MuiInputLabel-root {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    color: #383838 !important;
    top: -3px !important;
}
.tickect-filter .MuiOutlinedInput-root{
    height: 40px !important;
}
.tickect-filter .MuiOutlinedInput-input{
    padding: 10px !important;
}
/* table */
.employee-table .MuiTableContainer-root {
    background: #FFFFFF !important;
    border-radius: 0px 0px 6px 6px !important;
}

.employee-table .MuiTableHead-root {
    background-color: #FFE6BB;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
    padding: 14px 15px;
    border: unset !important;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .employee-index {
    width: 40px;
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #383838;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .employee-index .MuiSvgIcon-root {
    font-size: 18px !important;
    color: #383838 !important;
    cursor: pointer !important;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .employee-name {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #383838;
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .department-name {
    text-align: left !important;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .employee-name .MuiSvgIcon-root {
    color: #383838 !important;
    cursor: pointer !important;
    width: 17px !important;
    height: 15px !important;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .employee-name .MuiSvgIcon-root:first-child {
    margin-left: 10px;
}

.employee-table .MuiTableBody-root .MuiTableRow-root {
    border-bottom: 0.3px solid #DDDDDD;
}

.employee-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    color: #000000;
}

.employee-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 12px 15px;
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #383838;
}

.employee-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root span {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-transform: capitalize;
    padding: 5px 5px !important;
    border-radius: 4px;
}

.employee-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root p {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #383838;
}

.employee-table .MuiTableBody-root .MuiTableRow-root:hover {
    background-color: unset !important;
    cursor: context-menu !important;
}

.employee-table .MuiTableBody-root .tickects-lasttablecell {
    display: flex;
    align-items: center;
    border-bottom: unset !important;
}
.employee-table-ticket  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root{
    margin-left: -16px !important;
    margin-top: 7px !important;
}
.employee-table .MuiTableBody-root .tickects-lasttablecell .view-btn {
    margin-right: 12px;
    cursor: pointer;
}

.employee-table .MuiTableBody-root .tickects-lasttablecell .edit-btn {
    cursor: pointer;
}

/* tickects page */
.employee-table .MuiTableContainer-root::-webkit-scrollbar {
    height: 0px !important;
}

.employee-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root .view-btn .MuiSvgIcon-root {
    color: #383838;
    font-size: 19px;
    cursor: pointer;
}

.employees-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employees-text h5 {
    font-family: 'Inter', sans-serif !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    color: #202224;

}

.employees-text .MuiButton-root img {
    margin-right: 7px;
}

.employees-text .MuiButton-root {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #FFFFFF;
    text-transform: capitalize;
    background: linear-gradient(0deg, #D2AB67, #D2AB67),
        linear-gradient(180deg, rgba(255, 255, 255, 0.04) -44.73%, rgba(255, 255, 255, 0) 126.04%);
    border: 1px solid #D2AB67;
    padding: 12px 10px 12px 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.employees-text .employee-btn {
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #FFFFFF;
    text-transform: capitalize;
    background: linear-gradient(0deg, #D2AB67, #D2AB67), linear-gradient(180deg, rgba(255, 255, 255, 0.04) -44.73%, rgba(255, 255, 255, 0) 126.04%);
    border: 1px solid #D2AB67;
    padding: 8px 12px 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;

}
.employees-text .employee-btn .MuiSvgIcon-root{
  margin-right: 5px;
  font-size: 20px;
} 

/* popup tickects */
.add_managers .MuiDialog-paper {
    max-width: 750px !important;
    width: 500px !important;
}

.add_tickets .MuiDialog-paper {
    max-width: 1250px !important;
    width: 900px !important;
}

.add_tickets .title_add_button img {
    margin-right: 6px;
    width: 20px;
}

.add_tickets_content {
    display: flex;
    justify-content: space-between;
    width: 700px !important;
}

.add_tickets_content_list h4 {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin: 0 0 15px 0;
    color: #000;
}

.add_tickets_content_list p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin: 0 0 0 0;
    color: #383838CC;
}
.add_tickets_content_list h6{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin: 0 0 0 0;
    color: #383838CC;
}
.add_tickets_content_list {
    width: 33.3%;
}

.add_tickets_content_1 .add_tickets_content_list:nth-child(3) p {
    color: #2F80ED;
    background-color: #EDF5FF;
    padding: 5px;
    border-radius: 4px;
    width: 75px;
    text-align: center;
}

.add_tickets_content_1 {
    margin-top: 25px;
}

.add_tickets_details .description {
    margin-top: 25px;
}

.add_tickets_details .description h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin: 0px 0px 05px 0px;
    color: #C69849;
}
.add_tickets_details .description a{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 10px 0px 0px 0px !important;
    color: #000;
}

.add_tickets_details .description p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0px 0px 0px 0px;
    color: #000;
}

.add_tickets_details .problem_images {
    margin-top: 25px;
}

.add_tickets_details .problem_images h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    margin: 0px 0px 05px 0px;
    color: #383838;
}

.add_tickets_details .problem_images p {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    margin: 0px 0px 0px 0px;
    color: #383838;
}

.problem_images_layout {
    display: flex;
    margin-top: 20px;
}

.problem_images_layout .problem_images_layout_content img {
    width: 83px;
    height: 91px;
}

.problem_images_layout .problem_images_layout_content_2 img {
    margin-left: 20px;
}

.table_add_tickets .title_add_button_1 {
    border: unset;
}

.table_add_tickets .title_add_button_1:hover {
    border: unset;
    background-color: unset;
}

.MuiDialog-paper .MuiIconButton-sizeMedium {
    color: #ff0000 !important;
}

/* tickects */
/* department popup */
.department_layout {
    padding: 20px;
}

.department_layout_title_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.department_layout_title_content .department_layout_title h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
    margin: 0;
}

.title_add_button {
    background: #D2AB67 !important;
    color: #fff !important;
    border-radius: 5px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    border: unset !important;
    text-transform: unset !important;
    padding: 6px 10px;
    font-family: "Inter", sans-serif;
    box-shadow: 0px 2px 4px 0px #38383826;
}

.add_departments .MuiDialog-paper {
    box-shadow: 0px 4px 4px 0px #D2AB6757 !important;
    border: 0.8px solid #D2AB67 !important;
    max-width: 550px !important;
    width: 550px !important;
    border-radius: 12px !important;
}

.add_departments .MuiDialogContent-dividers {
    border-top: 0.8px solid #D2AB6780 !important;
    border-bottom: 0.8px solid #D2AB6780 !important;
}

.add_departments .MuiDialogTitle-root {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #383838;
    line-height: 19.36px;
    text-align: left;
}

.add_departments .MuiDialogContent-dividers {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.add_departments .MuiDialogContent-dividers .employee-status p {
    font-family: 'Inter', sans-serif !important;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    color: #383838;
    text-align: center;
}

.add_departments .MuiTextField-root {
    font-family: "Inter", sans-serif !important;
    width: 100%;
    box-shadow: 0px 2px 12px 0px #D2AB6757;
    border-radius: 4px;
}

.add_departments .MuiInputLabel-formControl {
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #4F4F4F !important;
}
.add_departments .MuiFormLabel-asterisk{
    color: #ff0000 !important;
}
.MuiInputBase-input {
    height: 18px !important;
}

.MuiFormControl-root .MuiInputLabel-formControl.Mui-focused {
    font-family: "Inter", sans-serif !important;
    color: #4F4F4F !important;
    top: 0px !important;
}

.MuiFormControl-root .MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #D2AB6799 !important;
}

.footer_button .cancel_button {
    border-radius: 5px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    border: unset !important;
    text-transform: unset !important;
    padding: 6px 14px;
    font-family: "Inter", sans-serif;
    color: #ff0000 !important;
}

.footer_button .cancel_button:hover {
    background-color: unset !important;
}

.footer_button .add_button {
    background: #D2AB67 !important;
    color: #fff !important;
    border-radius: 5px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    border: unset !important;
    text-transform: capitalize !important;
    padding: 6px 32px !important;
    font-family: "Inter", sans-serif;
}

.departments_tabel {
    position: relative;
}

.departments_tabel .filter_table {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 999;
}

.departments_tabel .filter_table #basic-button:hover {
    background-color: unset;
}

.filter_menu .MuiPopover-paper {
    width: 500px;
    max-width: 500px;
    /* left: 970px !important; */
    top: 170px;
    border-radius: 12px;
}

.filter_menu_title {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter_title h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}

.filter_department {
    padding: 30px 15px 10px 15px;
    border-top: 1px solid #D2AB6799;
}

.filter_dropdown .MuiInputLabel-formControl {
    background-color: #FFF !important;
    padding: 1px 8px 1px 2px;
}

.filter_dropdown .MuiOutlinedInput-input {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: "Inter", sans-serif;
}

.filter_date_picker {
    margin-top: 20px;
}

.filter_date_picker .MuiInputBase-input {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: "Inter", sans-serif;
}

.apply_fliters {
    /* margin-top: 20px; */
    border-top: 1px solid #D2AB6799;
    padding-top: 10px;
    display: flex;
    justify-content: center;
}

.apply_fliters .MuiButtonBase-root {
    background: #D2AB67 !important;
    color: #fff !important;
    border-radius: 5px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    border: unset !important;
    text-transform: unset !important;
    font-family: "Inter", sans-serif;
    width: 100%;
    max-width: 80%;
}

.filter_menu_button .MuiButtonBase-root {
    background-color: unset !important;
    padding: 0;
    min-width: 34px;
}

.filter_date_picker {
    font-family: "Inter", sans-serif !important;
    width: 100%;
    box-shadow: 0px 2px 12px 0px #D2AB6757;
    border-radius: 4px;
}

.filter_dropdown .MuiFormControl-root {
    font-family: "Inter", sans-serif !important;
    width: 100%;
    box-shadow: 0px 2px 12px 0px #D2AB6757;
    border-radius: 4px;
}

/* department popup */

/* manager popup */
/* .add_managers .MuiDialog-paper{
    max-width: 750px !important;
    width: 500px !important;
} */
.add_tickets .MuiDialog-paper {
    max-width: 1250px !important;
    width: 900px !important;
}

.add_tickets .title_add_button img {
    margin-right: 6px;
    width: 20px;
}

.add_tickets_content {
    display: flex;
    justify-content: space-between;
    width: 600px;
}


.add_tickets_content_1 {
    margin-top: 25px;
}

.add_tickets_details .description {
    margin-top: 25px;
}

.add_tickets_details .description h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin: 0px 0px 05px 0px;
    color: #C69849;
}

.add_tickets_details .description p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0px 0px 0px 0px;
    color: #000;
    /* width: 750px;   */
}

.add_tickets_details .problem_images {
    margin-top: 25px;
}

.add_tickets_details .problem_images h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    margin: 0px 0px 05px 0px;
    color: #383838;
}

.add_tickets_details .problem_images p {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    margin: 0px 0px 0px 0px;
    color: #383838;
}

.problem_images_layout {
    display: flex;
    margin-top: 20px;
}

.problem_images_layout .problem_images_layout_content img {
    width: 83px;
    height: 91px;
    object-fit: cover;
    box-shadow: 0px 4px 4px 0px #D2AB6757;
    border: 0.8px solid #D2AB67;
    border-radius: 12px;
}

.problem_images_layout .problem_images_layout_content_2 img {
    margin-left: 20px;
}

.table_add_tickets .title_add_button_1 {
    border: unset;
}

.table_add_tickets .title_add_button_1:hover {
    border: unset;
    background-color: unset;
}

/* manager poup */


/* notification poup */
.notifications_layout {
    padding: 20px;
    height: 600px;
    overflow-y: scroll;
}

.notifications_title {
    border-bottom: 1px solid #38383880;
    padding-bottom: 10px;
    margin-bottom: 15px;

}

.notifications_title h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin: 0;
}

.notifications_title h3 span {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #00A651;
}

.notifications_tabs {
    margin-top: 20px;
}

.notifications_tabs .tabs {
    display: flex;
}

.notifications_tabs .tabs .MuiBox-root {
    border-color: unset;
    border: unset;
}

.notifications_tabs .tabs .MuiTabs-flexContainer {
    display: flex;
    flex-direction: column;
}

.notifications_tabs .tabs .MuiTab-root {
    text-transform: unset;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #383838;
    padding: 0;
    white-space: nowrap;
}

.notifications_tabs .tabs .MuiTab-root.Mui-selected {
    color: #00A651 !important;
}

.notifications_tabs .tabs .MuiTabs-indicator {
    background-color: unset;
    border: unset;
}

.notifications_tabs .tabs .tabs_values .MuiBox-root {
    padding: 0;
}

.main_tabs_values {
    background-color: #FFF;
    width: 100%;
    padding: 10px;
    margin-left: 40px;
    border: 0.8px solid #D2AB674D;
    box-shadow: 0px 4px 4px 0px #D2AB6757;
    border-radius: 12px;
}

.mini_values_tabs .MuiTabs-flexContainer {
    display: flex !important;
    flex-direction: row !important;
    border-bottom: 1px solid #D2AB6780;
}

.all_mini_inner {
    padding: 20px;
}

.main_tabs_values .MuiTab-root.Mui-selected {
    color: #D2AB67;
    border-bottom: 1px solid #D2AB67;
}

.today_read {
    display: flex;
    justify-content: space-between;
}

.today_read .today h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

.today_read .all_read h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    margin: 0;
    color: #092C4C;
}

.all_mini_inner .inner_card {
    box-shadow: 0px 2px 12px 0px #D2AB6757;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 5px;
    border: 0.8px solid #D2AB6780
}

.all_mini_inner .inner_card_1 {
    margin-top: 10px;
}

.inner_card_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.inner_card_title .inner_card_title_1 h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

.inner_card_title .inner_card_title_2 h4 {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

.inner_card .inner_card_code h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
}

.inner_card .description {
    margin-top: 30px;
}

.inner_card .description h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin: 0px 0px 05px 0px;
    color: #C69849;
}

.inner_card .description p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0px 0px 0px 0px;
    color: #000;
    /* width: 750px;   */
}

.inner_card .cancle_save {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.inner_card .cancle_save .read {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-transform: unset;
    color: #00A651;

}

.inner_card .cancle_save .read:hover {
    background-color: unset;
}

.inner_card .cancle_save .title_add_button_1 {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-transform: unset;
    padding: 10px 20px;
    border: unset;
    background: #D2AB67 !important;
    color: #fff !important;
    border-radius: 5px !important;
    margin-left: 10px;
}

.inner_card .cancle_save .title_add_button_1:hover {
    background-color: unset;
}

.click_upload {
    font-family: "Inter", sans-serif;
}

/* notification  */


/* profile */
.apexcharts-legend-text {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
    color: #383838 !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
}

.profile {
    padding: 100px 30px 20px;
}

.profile-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
}

.profile-card {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding: 35px 30px;
    width: 100%;
    /* border: 1px solid #D2AB67 !important; */
    box-shadow: 0px 4px 4px 0px #D2AB6757 !important;
    background: #FFFFFF !important;
    border-radius: 12px !important;
}

.profile-image {
    align-self: center;
}

.profile-image img {
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 50%;
}

.profile-text {
    margin-bottom: 30px;
}

.profile-text h4 {
    font-family: "Inter", sans-serif !important;
    font-weight: 600 !important;
    line-height: 16px;
    color: #383838 !important;
    font-size: 18px;
    text-align: center;
}

.profile-name ul {
    padding: 0px !important;
}

.profile-name ul li {
    border-bottom: 0.8px solid #D2AB67 !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.profile-name ul li h5 {
    font-family: "Inter", sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 16.94px;
    margin-bottom: 20px;
    color: #383838 !important;
}

.profile-name ul li p {
    font-family: "Inter", sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #383838 !important;
}

.profile-name ul li:not(:last-child) {
    margin-bottom: 30px;
}

.profile-name .change-btn {
    margin-top: 35px;
    padding: 10px 12px !important;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    background: #D2AB67 !important;
    color: #fff !important;
    border-radius: 5px !important;
    text-transform: capitalize;
}

/* profile */


@media (min-width:992px) and (max-width:1199px) {
    .side_right_grid .sidebar-content {
        height: 795px;
    }

    .side_right_grid .dashboard-section {
        padding: 20px;
    }

    .side_right_grid .dashboard-section .dashboard_box {
        max-width: 205px;
    }
/* 
    .side_right_grid .pagination-options::after {
        height: 113px;
    }

    .side_right_grid .pagination-options::before {
        height: 113px;
    } */
}

@media (min-width:1200px) and (max-width:1299px) {
    .side_right_grid .charts-section .piechart .apexcharts-legend {
        right: 45px !important;
    }
}

/* tabs styles */
.employees-1{
    margin-top: 20px;
}
.tabs-section{
    margin: 20px 15px 0px 20px;
}
.tabs-section .MuiTab-root{
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    color: #383838 !important;
    text-transform: capitalize;
    padding: 10px 12px !important;
}
.tabs-section .MuiTab-root:first-child{
    margin-right: 30px;
}
.tabs-section .MuiTab-root:last-child{
    margin-left: 30px;
}
.tabs-section .Mui-selected{
    color: #D2AB67 !important;
}
.tabs-section .MuiTabs-indicator{
    height: 0px !important;
    background-color: #D2AB67 !important;
    border-bottom: 2px solid #D2AB67 !important;
}
.country-code{
    display: flex;
}
.country-code .react-tel-input{
    width: 100px !important;
    background-color: #FFF;
}
.country-code .react-tel-input .form-control{
    width: 100px !important;
    height: 52px;
    background: #fff;
}
.country-code .react-tel-input .flag-dropdown{
    height: 52px;
}
.public-notifications .notification-public-btn-1{
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    color: #FFFFFF;
    text-transform: capitalize;
    background: linear-gradient(0deg, #D2AB67, #D2AB67), linear-gradient(180deg, rgba(255, 255, 255, 0.04) -44.73%, rgba(255, 255, 255, 0) 126.04%);
    border: 1px solid #D2AB67;
    padding: 7px 16px 7px 16px;
    border-radius: 6px;
    display: flex !important;
    align-items: center !important;
    margin-left: 10px !important;
}
.public-notifications .notification-public-btn-1 .MuiSvgIcon-root{
    font-size: 20px;
    margin-right: 5px;
}

.add_departments  input{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #4F4F4F !important;
}


.add_departments  input[type=file]::file-selector-button {
    color: #FFFFFF;
    text-transform: capitalize;
    background: linear-gradient(0deg, #D2AB67, #D2AB67), linear-gradient(180deg, rgba(255, 255, 255, 0.04) -44.73%, rgba(255, 255, 255, 0) 126.04%);
    font-size: 12px;
    font-weight: 500;
    line-height: 23px;
    border-radius: 4px;
    border: none !important;
    margin-right: 15px;
}

.ticket_status_table_main{
    height: 150px !important;
    overflow-y: scroll !important;
    margin: 20px 0px;
}
.ticket_status_table_main::-webkit-scrollbar{
    width: 4px !important;
}
.ticket_status_table_main::-webkit-scrollbar-thumb{ 
    max-height: 50% !important;  
    height: 50% !important;
    background-color: #FFE6BB !important;
}

  .ticket_status_table .MuiTableCell-root{
    padding: 8px 8px !important;
  }
  
  .ticket_status_table .MuiTableHead-root{
    background-color: #FFE6BB;
  }
  .ticket_status_table .MuiTableBody-root .MuiTableRow-root {
    border-bottom: 0.3px solid #DDDDDD;
}
.ticket_status_table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
    font-family: 'Inter', sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #383838;
    white-space: nowrap;
}
.ticket_status_table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 8px 8px !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #383838;
}

@media (min-width:992px) and (max-width:1140px) {
    .employees-text h5{
        font-size: 16px;
    }
    .tabs-section .MuiTab-root{
        font-size: 14px;
    }
    .tabs-section .MuiTab-root:first-child {
        margin-right: 20px;
    }
}
 .view-btn .MuiTooltip-tooltip {
    background-color: #ff0000 !important; /* Background color of the tooltip */
    color: #fff !important;           /* Text color of the tooltip */
    font-size: 14px !important;       /* Font size */
    border-radius: 4px !important;    /* Rounded corners */
}

