@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.login-section {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.login-image {
    width: 49%;
    height: 100%;
    background-image: url('../../assets/left-login-background.svg');
    background-repeat: no-repeat;
    position: relative;
}

.login-image .login_logo {
    position: absolute;
    left: 10px;
    top: 20px;
}

.login-image .login_logo_1 {
    position: absolute;
    bottom: 0;
    right: -5px;
}

.login-image .login_logo_1 img {
    width: 100%;
    height: 600px;
}


.login-main {
    width: 52%;
    height: 104%;
    background-image: url('../../assets/right-login-background.svg');
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    top: -27px;

}

.login-content {
    width: 300px;
    margin-top: 40px;
}

.login-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-logo .login-img {
    width: 250px;
    margin-bottom: 32px;
}

.login-content h4 {
    font-family: "Inter", sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 10px;
    text-align: center;
}

.login-content p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    margin-bottom: 10px;
}

.login-logo .MuiButton-root {
    background: #D2AB67;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    color: #FFFFFF;
    width: 100%;
    text-transform: capitalize;
}

.login-logo .MuiButton-root:hover {
    background: #D2AB67;
    color: #FFFFFF;
}

.login-content .MuiOutlinedInput-input {
    color: #4F4F4F;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    padding: 14px !important;
    background-color: #FFFFFF;
}

.login-content .MuiInputLabel-root {
    top: -3px !important;

}

.login-content .MuiOutlinedInput-notchedOutline {
    border: 1px solid #D2AB6799 !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 12px 0px #D2AB6757 !important;
}

.login-content .MuiInputLabel-root.Mui-focused {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: #4F4F4F;
}

.login-content .MuiFormLabel-asterisk {
    color: #ff0000 !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
}

.forgot-text {
    width: 100%;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    font-family: "Inter", sans-serif;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 22.4px !important;
    color: #00A651 !important;
    background-color: unset !important;
    text-align: end !important;
    text-transform: capitalize !important;
}

.forgot-text:hover {
    background-color: unset !important;

}

/* new styles */
.MuiGrid-root a {
    text-decoration: none !important;
}

.login-content .login-link {
    color: #00A651 !important;
    text-align: end !important;
    font-family: "Inter", sans-serif;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    text-transform: capitalize !important;
    margin-top: 20px;
}

.send-otp-number input {
    width: 44px !important;
    height: 60px;
    border-radius: 15px;
    border: 0.6px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    position: relative;
    color: #2c0202;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-top: 05px;
}

.send-otp-number inpuT:focus {
    outline: unset;
}

.send-otp-number input:not(:first-child) {
    margin-left: 10px;
}

.send-otp-number span {
    font-size: 0;
}

.send-otp-number label {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    margin-bottom: 40px;
}

.login-logo_2 {
    margin-top: 30px;
    gap: 10px
}

.confirm_password h4 {
    margin-bottom: 20px;
}

.login-content .MuiFormHelperText-root {
    text-align: left;
    margin-top: 7px;
    margin-left: 2px;
}

.login-content .main_otp h4 {
    font-size: 16px;
}

.login-content .MuiOutlinedInput-root {
    background-color: #FFFFFF !important;
}
.btn-login .MuiButton-root {
    background: #00A651 !important;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    color: #FFFFFF;
    width: 100%;
    text-transform: capitalize;
}
.btn-login .MuiButton-root:hover {
    background: #00a650ac;
    color: #FFFFFF;
}

@media (min-width:992px)and (max-width:1199px) {
    .login-image .login_logo_1 img {
        height: auto;
    }

    .login-image .login_logo img {
        width: 80px;
    }
}

@media (min-width:1200px)and (max-width:1499px) {
    .login-image .login_logo_1 img {
        height: 550px;
    }

    .login-image .login_logo img {
        width: 80px;
    }
}